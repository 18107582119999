<template>
    <transition name="fade">
        <div class="self-pick-all" v-if="isShow">
            <div class="self-pick-bg"></div>
            <div class="self-pick-main">
                <img src="@/assets/payment/close.png" alt="" class="self-close" @click="selfClose">
                <div class="self-title">留下您的联系方式</div>
                <div class="self-address">
                    自提地址：Unit 133-11121 Horseshoe Way Richmond, BC, V7A5G7 Canada
                </div>
                <div class="self_box">
                    <div class="self-row">
                        <div class="self-item">
                            <p>姓氏</p>
                            <input type="text" placeholder="拼音或英文" v-model="pickUpInfo.surname">
                        </div>
                        <div class="self-item">
                            <p>名字</p>
                            <input type="text" placeholder="拼音或英文" v-model="pickUpInfo.name">
                        </div>
                    </div>
                    <div class="self-row">
                        <div class="self-item">
                            <p>联系电话</p>
                            <input type="text" placeholder="请输入您电话号码" maxlength="13" v-model="pickUpInfo.tel">
                        </div>
                    </div>
                    <div class="self-row">
                        <div class="self-item">
                            <p>邮箱</p>
                            <input type="text" placeholder="请输入您的邮箱地址" v-model="pickUpInfo.email">
                        </div>
                    </div>
                    <div class="self-row">
                        <div class="self-item">
                            <p>自提时间</p>
                            <div class="timeBox">
                                <van-radio-group v-model="pick_up_time">
                                    <div class="timeItem" :style="{ color: pick_up_time == item ? '#3284FA' : '#000000' }"
                                        v-for="(item, index) in options" :key="index" @click="pickUpClick(item)">
                                        <span class="text">周{{ toChineseNum(item) }}</span>
                                        <span class="number">{{ index == 5 ? '10:00-15:00' : '13:00-18:00' }}</span>
                                        <van-radio :name="item" />
                                    </div>
                                </van-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="submit-button" @click="submitSelfPick">
                    确认
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { getSelfInfo } from '@/api/zh/order.js'
import { toChineseNum } from '@/untils/js/common.js'
export default {
    props: {
        isPickUp: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShow: false,
            pickUpInfo: {
                name: '',
                surname: '',
                tel: '',
                email: '',
            },
            pick_up_time: 1,
            options: [
                1,
                2,
                3,
                4,
                5,
                6
            ]
        }
    },
    created() {
        this.getSelfInfoHandle()
    },
    methods: {
        toChineseNum,
        getSelfInfoHandle() {
            // 获取自提信息
            getSelfInfo().then(res => {
                if (res && res.data) {
                    this.pickUpInfo = res.data
                }
            })
        },
        pickUpClick(index) {
            this.pick_up_time = index
        },
        selfClose() {
            // 取消自选
            this.$emit('close', false)
            this.isShow = false
        },
        submitSelfPick() {
            if (this.pickUpInfo.surname == '') {
                this.$notify({ type: 'warning', message: '请输入您的姓氏' })
                return false
            }
            if (this.pickUpInfo.name == '') {
                this.$notify({ type: 'warning', message: '请输入您的名字' })
                return false
            }
            if (this.pickUpInfo.tel == '' || this.pickUpInfo.tel.length < 10) {
                this.$notify({ type: 'warning', message: '请正确输入您的手机号' })
                return false
            }
            if (this.pick_up_time == '') {
                this.$notify({ type: 'warning', message: '请正确输入您的自提时间' })
                return false
            }
            /* eslint-disable */
            let patt = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
            if (this.pickUpInfo.email == '' || !patt.test(this.pickUpInfo.email)) {
                this.$notify({ type: 'warning', message: '请正确输入您的邮箱' })
                return false
            }
            this.pickUpInfo.pick_up_time = this.pick_up_time
            console.log(this.pickUpInfo)
            this.$emit('submit', this.pickUpInfo)
            this.isShow = false
        }
    },
    watch: {
        isPickUp(val) {
            if (val) {
                this.isShow = val
            }
        }
    }
}
</script>

<style scoped lang="less">
.self-pick-all {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    .self-pick-bg {
        width: 100%;
        height: 100%;
        background-color: #333;
        opacity: .3;
    }

    .self-pick-main {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        background-color: #fff;
        z-index: 9;
        width: 320px;
        height: 500px;
        padding: 0 20px;
        box-sizing: border-box;

        .self-close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px
        }

        .self-title {
            width: 100%;
            height: 60px;
            border-bottom: 1px solid #f1f1f1;
            color: #333;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
        }
        .self_box {
            width: 100%;
            height: 320px;
            overflow-y: scroll;
        }
        .self-row {
            width: 100%;
            display: flex;
            margin-top: 12px;

            .self-item {
                flex: 1;

                &:nth-of-type(2) {
                    margin-left: 10px
                }

                p {
                    font-size: 14px;
                    color: #333;
                    line-height: 35px;

                    &::before {
                        content: '';
                        width: 2px;
                        height: 12px;
                        display: inline-block;
                        background-color: #2D77E0;
                        margin-right: 5px;
                        vertical-align: -2px;
                    }
                }

                input {
                    width: 100%;
                    height: 40px;
                    outline: none;
                    border: 1px solid #3284FA;
                    box-sizing: border-box;
                    border-radius: 5px;
                    padding: 0 10px;
                    font-size: 14px;
                }

                .timeBox {
                    /deep/ .van-radio-group {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .van-radio {
                            .van-radio__icon {
                                font-size: 14px;
                            }
                        }

                        .timeItem {
                            margin-bottom: 10px;
                            padding: 8px 5px;
                            font-size: 12px;
                            line-height: 16px;
                            border: 1px solid #3284FA;
                            border-radius: 5px;
                            width: calc(50% - 5px);
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: space-around;

                            &:nth-of-type(5),
                            &:nth-of-type(6) {
                                margin-bottom: 0;
                            }

                            span {
                                display: inline-block;
                                transform: scale(0.9);
                            }
                        }
                    }
                }
            }
        }

        .submit-button {
            width: 192px;
            height: 40px;
            background: linear-gradient(90deg, #2D77E0 3.17%, #245FB3 100%);
            border-radius: 21px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            margin: 25px auto 0 auto;
            font-size: 16px;
        }
    }

    .self-address {
        padding: 10px 0 0 0;
        font-size: 14px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}</style>